<template>
  <div class="filter__group_body-content_checkboxes">
    <BaseCheckbox
      v-for="(i, idx) in item.items"
      :key="`${i.title}-${idx}`"
      class="checkbox--gray"
      :class="{ 'checkbox--hidden': isCollapsible && !open && idx > 4 }"
      :label="i.title"
      :checked="i.selected"
      @input="$emit('selectFilter', { key: item.key, filter: i })"
    ></BaseCheckbox>
    <BaseButton
      v-if="isCollapsible && !open && item.items.length > 5"
      class="button--only-text button--only-text-underline"
      @click="open = true"
      text="View all..."
    ></BaseButton>
  </div>
</template>
<script>
import BaseCheckbox from "@/components/inputs/BaseCheckbox.vue";
import BaseButton from "./buttons/BaseButton.vue";

export default {
  props: {
    isCollapsible: { type: Boolean },
    item: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  components: {
    BaseCheckbox,
    BaseButton,
  },
  data() {
    return {
      open: false,
    };
  },
  methods: {},
};
</script>
<style lang="scss" scoped>
.filter {
  position: relative;
  margin: 5px 0;
  .text {
    @include publictext($fs: 18px, $fw: 400, $lh: 1.15);
    color: $white;
    @media only screen and (max-width: $lg) {
      font-size: 14px;
    }
  }
  &__header {
    display: flex;
    align-items: center;
  }
  &__body {
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 0;
    right: 0;
    min-width: 500px;
    max-width: 500px;
    height: 100vh;
    background-color: #111;
    padding: 24px;
    z-index: 9999;
    @media screen and (max-width: $xs) {
      min-width: 86vw;
    }
    &_content {
      overflow-y: auto;
      height: 100%;
      padding-bottom: 15px;
    }
    &_header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 28px;
      .text {
        font-size: 24px;
      }
      &_right {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 20px;
      }
    }
    &_search {
      margin-bottom: 16px;
    }
    &_results {
      display: flex;
      flex-direction: column-reverse;
      gap: 8px;
      margin-bottom: 2px;
      &-values {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 8px 4px;
        &:empty {
          & + p {
            display: none;
          }
        }
      }
      p {
        width: 100%;
        font-size: 14px;
        line-height: 1.14;
      }
    }
  }
  &__group {
    border-bottom: 0.5px solid #707070;
    padding-bottom: 20px;
    &--buttons {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      padding: 10px;
      margin: auto 0 0;
      .button {
        margin: 0 1.1rem 10px;
        @media screen and (max-width: $xs) {
          width: 100%;
        }
      }
    }
    &_header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 24px 0 0;
      cursor: pointer;
      .icon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 24px;
        height: 24px;
        cursor: pointer;
        svg {
          width: 16px;
          height: 9px;
          transform: rotate(180deg);
          ::v-deep g {
            fill: $white;
            transition: all 0.3s ease-in-out;
          }
        }
        &--down {
          transform: rotate(0deg);
        }
      }
    }
    &_body {
      max-height: 0;
      overflow-y: auto;
      transition: all 0.3s ease;
      &-content {
        display: flex;
        flex-wrap: wrap;
        padding: 20px 0 0;
        // @media screen and (max-width: $xs) {
        //   padding: 10px 30px 15px 10px;
        // }
        &_checkboxes {
          display: flex;
          flex-direction: column;
          gap: 12px;
        }
      }
      &--open {
        max-height: 30rem;
        &.filter__group_body--open-xl {
          max-height: 40rem;
        }
      }
      &-row {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        gap: 12px;
        div {
          flex: 1;
          margin-top: 0;
          // @media screen and (max-width: $xs) {
          //   width: 100%;
          //   max-width: 200px;
          // }
        }
      }
    }
  }
  &--mobile-full {
    .filter {
      &__header {
        @media screen and (max-width: $xs) {
          width: 100%;
        }
      }
    }
  }
}
</style>

